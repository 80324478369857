import { Typography } from "@mui/material";
import * as React from "react";

function Admin() {
  return (
    <Typography variant="h10" color="">
      Admin
    </Typography>
  );
}

export default Admin;
